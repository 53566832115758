import { ref, watch } from '@vue/composition-api'
import store from '@/store'
import addressController from '@/views/apps/adresses/Controller'
import userController from '@/views/apps/user/user-list/useUsersList'
import roleController from '@/views/apps/role/Controller'
import communicationController from '@/views/apps/communications/Controller'
import isbnController from '@/views/apps/isbns/Controller'
import subscriptionController from '@/views/apps/subscriptions/Controller'
import packageController from '@/views/apps/packages/Controller'
import rideController from '@/views/apps/rides/Controller'
import divisionController from '@/views/apps/divisions/Controller'
import foodController from '@/views/apps/foods/Controller'
import mealController from '@/views/apps/meals/Controller'
import productController from '@/views/apps/products/Controller'
import shopController from '@/views/apps/shops/Controller'
import agentController from '@/views/apps/agents/Controller'
import managerController from '@/views/apps/managers/Controller'
import cartController from '@/views/apps/carts/Controller'
import orderController from '@/views/apps/orders/Controller'
import appController from '@/views/apps/apps/Controller'
import {i18n} from "@/plugins/i18n";

export default function controller() {
  const activityColumns = [
    {
      text: 'User',
      value: 'user.name',
      filter: {
        place_holder: 'Name ou email...', type: 'text', columns: ['users.name', 'users.email', 'users.code'], join_table: 'users', join_column: 'users.id', local_column: 'activities.user_id',
      },
    },
    {
      text: 'Action',
      value: 'action',
      filter: {
        place_holder: '', type: 'text',
      },
    },

    /* { text: 'Type', value: 'actionable_type' }, */
    {
      text: 'Description',
      value: 'description',
      filter: {
        place_holder: '', type: 'text',
      },
    },
    {
      text: 'Date',
      value: 'created_at',
      menu: false,
      filter: {
        place_holder: 'Date1 - Date2', type: 'date', menu: false, ref: 'menu', range: true,
      },
    }]
  /*const {
    tableColumns: manuscriptColumns,
  } = manuscriptController()
  const {
    tableColumns: authorColumns,
  } = authorController()
  const {
    tableColumns: addressColumns,
  } = addressController()*/



  const { tableColumns: addressColumns } = addressController()

  /*const { tableColumns: countryColumns } = countryController()
  const { tableColumns: colorColumns } = colorController()*/

  const { tableColumns: subscriptionColumns } = subscriptionController()
  const { tableColumns: packageColumns } = packageController()
  const { tableColumns: rideColumns } = rideController()
  const { tableColumns: divisionColumns } = divisionController()
  const { tableColumns: foodColumns } = foodController()
  const { tableColumns: mealColumns } = mealController()
  const { tableColumns: productColumns } = productController()
  const { tableColumns: shopColumns } = shopController()
  const { tableColumns: cartColumns } = cartController()
  const { tableColumns: orderColumns } = orderController()
  const { tableColumns: agentColumns } = agentController()
  const { tableColumns: managerColumns } = managerController()
  const { tableColumns: appColumns } = appController()
  const { filters: userColumns } = userController()
  const { filters: roleColumns } = roleController()

  const tabs = [
    { tab: 'Address', label: 'Adresses', columns: addressColumns.map(ele => ({ value: ele.value, text: ele.text, permissions: ['view', 'edit'] })) },
    { tab: 'Activities', label: 'Activities', columns: activityColumns.map(ele => ({ ...ele, permissions: ['view', 'edit'] })) },

/*    { tab: 'Countries', label: 'Pays', columns: countryColumns.map(ele => ({ ...ele, permissions: ['view', 'edit'] })) },
    { tab: 'Colors', label: 'Codes Couleurs', columns: colorColumns.map(ele => ({ ...ele, permissions: ['view', 'edit'] })) },*/

    { tab: 'Subscription', label: i18n.t('Subscriptions'), columns: subscriptionColumns.map(ele => ({ ...ele, permissions: ['view', 'edit'] })) },
    { tab: 'Package', label: i18n.t('Packages'), columns: packageColumns.map(ele => ({ ...ele, permissions: ['view', 'edit'] })) },
    { tab: 'Ride', label: i18n.t('Rides'), columns: rideColumns.map(ele => ({ ...ele, permissions: ['view', 'edit'] })) },
    { tab: 'Division', label: i18n.t('Divisions'), columns: divisionColumns.map(ele => ({ ...ele, permissions: ['view', 'edit'] })) },

    { tab: 'Food', label: i18n.t('Foods'), columns: foodColumns.map(ele => ({ ...ele, permissions: ['view', 'edit'] })) },
    { tab: 'Meal', label: i18n.t('Meals'), columns: mealColumns.map(ele => ({ ...ele, permissions: ['view', 'edit'] })) },
    { tab: 'Product', label: i18n.t('Products'), columns: productColumns.map(ele => ({ ...ele, permissions: ['view', 'edit'] })) },
    { tab: 'Shop', label: i18n.t('Shops'), columns: shopColumns.map(ele => ({ ...ele, permissions: ['view', 'edit'] })) },
    { tab: 'Cart', label: i18n.t('Carts'), columns: cartColumns.map(ele => ({ ...ele, permissions: ['view', 'edit'] })) },
    { tab: 'Order', label: i18n.t('Orders'), columns: orderColumns.map(ele => ({ ...ele, permissions: ['view', 'edit'] })) },

    { tab: 'Role', label: i18n.t('Role'), columns: roleColumns.value.map(ele => ({ ...ele, permissions: ['view', 'edit'] })) },
    { tab: 'User', label: i18n.t('Users'), columns: userColumns.value.map(ele => ({ ...ele, permissions: ['view', 'edit'] })) },
    { tab: 'Agent', label: i18n.t('Agents'), columns: agentColumns.map(ele => ({ ...ele, permissions: ['view', 'edit'] })) },
    { tab: 'Manager', label: i18n.t('Manager'), columns: managerColumns.map(ele => ({ ...ele, permissions: ['view', 'edit'] })) },
    { tab: 'App', label: i18n.t('App'), columns: appColumns.map(ele => ({ ...ele, permissions: ['view', 'edit'] })) },
    { tab: null, label: i18n.t('General'), columns: [] },
  ]
  const types = [
    { value: 'create', text: i18n.t('Create') },
    { value: 'view', text: i18n.t('View') },
    { value: 'edit', text: i18n.t('Update') },
    { value: 'delete', text: i18n.t('Delete') },
  ]

  const blankItem = {
    id: null,
    name: null,
    display_name: null,
    permissions: [],
  }
  const form = ref(null)
  const items = ref([])
  const item = ref({})
  const filters = ref([
    /* { text: 'Code', value: 'name', filter: {type: 'text'} }, */
    { text: 'Name', value: 'display_name', filter: { type: 'text' } },
    {
      text: 'Actions',
      value: 'actions',
      align: 'center',
      sortable: false,
    },
  ])
  const isSnackbarBottomVisible = ref(false)
  const snackBarMessage = ref('')
  const isFormActive = ref(false)
  const generateDialog = ref(false)
  const deleteDialog = ref(false)
  const searchQuery = ref('')
  const totalItems = ref(0)
  const loading = ref(false)
  const options = ref({
    rowsPerPage: 50,
    itemsPerPage: 50,
    page: 1,
    sortDesc: [true],
  })
  const page = ref(1)
  const pageCount = ref(0)
  const selectedRows = ref([])
  const resetForm = () => {
    if (form.value) form.value.reset()
  }

  // new item
  const openDialog = val => {
    if (val) {
      item.value = val
    } else {
      item.value = JSON.parse(JSON.stringify(blankItem))
      resetForm()
    }
    isFormActive.value = !isFormActive.value
  }

  const openDialogGenerate = () => {
    generateDialog.value = !generateDialog.value
  }

  // fetch data
  const fetchItems = () => {
    isFormActive.value = false
    store
      .dispatch('permission/fetchItems', {
        filters: filters.value.filter(ele => ele.filter != null && ele.filter.value !== '' && ele.filter.value != null && (ele.filter.value.constructor !== Array || ele.filter.value.length > 0)).map(ele => ({ ...ele.filter, key: ele.value, values: null })),
        term: searchQuery.value,
        orderBy: options.value.sortBy ? options.value.sortBy[0] : 'id',
        // eslint-disable-next-line no-nested-ternary
        direction: options.value.sortDesc ? (options.value.sortDesc[0] ? 'DESC' : 'ASC') : 'DESC',
        page: options.value.page,
        per_page: options.value.itemsPerPage,
      })
      .then(response => {
        const { data, total } = response.data

        items.value = response.data
        totalItems.value = response.data.length

        // remove loading state
        loading.value = false
      })
      .catch(error => {
        console.log(error)
      })
      .then(() => {
        loading.value = false
      })
  }
  const confirmDelete = val => {
    item.value = val
    deleteDialog.value = true
  }
  const deleteItem = () => {
    if (item.value) {
      store.dispatch('permission/deleteItem', item.value.id)
        .then(response => {
          deleteDialog.value = false
          fetchItems()
          // eslint-disable-next-line no-unused-vars
        }).catch(error => {

        })
    }
  }
  // eslint-disable-next-line no-unused-vars
  let timeout = null
  watch([filters], () => {
    if (timeout) {
      clearTimeout(timeout)
    }
    timeout = setTimeout(() => {
      loading.value = true
      selectedRows.value = []
      fetchItems()
    }, 500)
  }, { deep: true })

  watch([searchQuery, options], () => {
    loading.value = true
    selectedRows.value = []
    fetchItems()
  }, { deep: true })

  return {
    types,
    tabs,
    item,
    items,
    filters,
    searchQuery,
    totalItems,
    loading,
    options,
    selectedRows,
    isFormActive,
    blankItem,
    form,
    page,
    pageCount,
    snackBarMessage,
    isSnackbarBottomVisible,
    resetForm,
    deleteDialog,
    generateDialog,
    confirmDelete,
    openDialog,
    openDialogGenerate,
    deleteItem,
    fetchItems,
  }
}
