import {
  mdiAccountCheckOutline,
  mdiAccountOutline,
  mdiAccountPlusOutline,
  mdiAccountRemoveOutline,
  mdiCogOutline,
  mdiDatabaseOutline,
  mdiDnsOutline,
  mdiPencilOutline,
} from '@mdi/js'
import { ref, watch } from '@vue/composition-api'
import store from '@/store'

export default function useAuthorsList() {
  const blankItem = {
    id: null,
    ful_name: null,
    email: [],
    phone: null,
    avatar: null,
    source_id: null,
    status_id: null,
    user_id: null,
    old_author: null,
    addresses: [],
    password: null,
    changePassword: true,
    compte_auteur: null,
  }
  const form = ref(null)
  const items = ref([])
  const item = ref({})
  const downloadObject = ref({ loading: false, url: '' })
  const tableColumns = [
    {
      text: 'Actions',
      value: 'actions',
      align: 'center',
      sortable: false,
    },
    {
      text: 'Auteur', value: 'author.full_name', align: 'center', filter: { place_holder: '', type: 'text' },
    },
    {
      text: 'Compte auteur', value: 'author.compte_auteur', align: 'center', filter: { place_holder: '', type: 'text' },
    },
    {
      text: 'Téléphone', value: 'author.phone', align: 'center', filter: { place_holder: '', type: 'text' },
    },
    {
      text: 'Email', value: 'author.email', align: 'center', filter: { place_holder: '', type: 'text' },
    },
    {
      text: 'Adresse', value: 'address', align: 'center', filter: { place_holder: '', type: 'text' },
    },
    {
      text: 'Cpl', value: 'address2', filter: { place_holder: '', type: 'text' }, align: 'center',
    },
    {
      text: 'Département', value: 'department', filter: { place_holder: '', type: 'text' }, align: 'center',
    },
    {
      text: 'Code postal', value: 'postal_code', filter: { place_holder: '', type: 'text' }, align: 'center',
    },
    {
      text: 'Ville', value: 'city', filter: { place_holder: '', type: 'text' }, align: 'center',
    },
    {
      text: 'Pays',
      value: 'country.full_name',
      align: 'center',
      filter: {
        place_holder: '',
        type: 'select',
        values: store.state.app.pays,
        label: 'full_name',
        id: 'id',
        multiple: true,
        column: 'addresses.country_id',
        join_table: 'countries',
        join_column: 'countries.id',
        local_column: 'addresses.country_id',
      },
    },
  ]
  const isSnackbarBottomVisible = ref(false)
  const snackBarMessage = ref('')
  const isFormActive = ref(false)
  const deleteDialog = ref(false)
  const searchQuery = ref('')
  const payFilter = ref(null)
  const sourceFilter = ref(null)
  const statusFilter = ref(null)
  const totalItems = ref(0)
  const loading = ref(false)
  const filters = ref([])
  const options = ref({
    rowsPerPage: 50,
    itemsPerPage: 50,
    page: 1,
    sortDesc: [true],
  })
  const page = ref(1)
  const pageCount = ref(0)
  const authorTotalLocal = ref([])
  const selectedRows = ref([])
  const resetForm = () => {
    if (form.value) form.value.reset()
  }

  // new item
  const openDialog = val => {
    if (val) {
      item.value = val
      item.value.addresses = item.value.addresses || []
    } else {
      item.value = JSON.parse(JSON.stringify(blankItem))
      resetForm()
    }
    isFormActive.value = !isFormActive.value
  }

  // fetch data
  const fetchItems = download => {
    isFormActive.value = false
    downloadObject.value = { loading: true, url: null }
    store
      .dispatch('address/fetchItems', {
        filters: filters.value.filter(ele => ele.filter != null && ele.filter.value !== '' && ele.filter.value != null && (ele.filter.value.constructor !== Array || ele.filter.value.length > 0))
          .map(ele => ({ ...ele.filter, key: ele.value, values: null })),
        term: searchQuery.value,
        status: statusFilter.value,
        pay: payFilter.value,
        source: sourceFilter.value,
        download,
        orderBy: options.value.sortBy ? options.value.sortBy[0] : 'author.full_name',
        // eslint-disable-next-line no-nested-ternary
        direction: options.value.sortDesc ? (options.value.sortDesc[0] ? 'ASC' : 'DESC') : 'ASC',
        page: options.value.page,
        per_page: options.value.itemsPerPage,
      })
      .then(response => {
        if (download) {
          downloadObject.value = {
            loading: false,
            url: response.data || null,
          }
        } else {
          const {
            data,
            total,
            authorTotal,
          } = response.data

          items.value = data.map(e => {
            e.old_author = e.old_author

            return e
          })
          store.commit('app-author/USERS', items.value)
          totalItems.value = total
          authorTotalLocal.value = authorTotal
        }

        // remove loading state
        loading.value = false
      })
      .catch(error => {
        console.log(error)
      })
  }
  const confirmDelete = val => {
    item.value = val
    deleteDialog.value = true
  }
  const deleteItem = () => {
    if (item.value) {
      loading.value = true
      store.dispatch('app-author/deleteAuthor', item.value)
        .then(response => {
          loading.value = false
          deleteDialog.value = false
          fetchItems()
          // eslint-disable-next-line no-unused-vars
        }).catch(error => {

      })
    }
  }

  const avatarText = value => {
    if (!value) return ''
    const nameArray = value.split(' ')

    return nameArray.map(word => word.charAt(0).toUpperCase()).join('')
  }

  watch([searchQuery, payFilter, sourceFilter, statusFilter, options, filters], () => {
    loading.value = true
    selectedRows.value = []
    fetchItems()
  }, { deep: true })

  // *===============================================---*
  // *--------- UI ---------------------------------------*
  // *===============================================---*

  const resolveAuthorRoleVariant = pay => {
    if (pay === 'subscriber') return 'primary'
    if (pay === 'author') return 'warning'
    if (pay === 'maintainer') return 'success'
    if (pay === 'editor') return 'info'
    if (pay === 'administrator') return 'error'

    return 'primary'
  }

  const resolveAuthorRoleIcon = pay => {
    if (pay === 'subscriber') return mdiAccountOutline
    if (pay === 'author') return mdiCogOutline
    if (pay === 'maintainer') return mdiDatabaseOutline
    if (pay === 'editor') return mdiPencilOutline
    if (pay === 'administrator') return mdiDnsOutline

    return mdiAccountOutline
  }

  const resolveAuthorStatusName = status => {
    if (status === 0) return 'Inactive'
    if (status === 1) return 'Active'

    return 'Active'
  }

  const resolveAuthorStatusVariant = status => {
    if (status === 0) return 'warning'
    if (status === 1) return 'success'

    return 'primary'
  }

  const resolveAuthorTotalIcon = total => {
    if (total === 'Total Authors') return { icon: mdiAccountOutline, color: 'primary' }
    if (total === 'Paid Authors') return { icon: mdiAccountPlusOutline, color: 'error' }
    if (total === 'Active Authors') return { icon: mdiAccountCheckOutline, color: 'success' }
    if (total === 'Pending Authors') return { icon: mdiAccountRemoveOutline, color: 'warning' }

    return { icon: mdiAccountOutline, color: 'primary' }
  }

  return {
    downloadObject,
    item,
    items,
    filters,
    tableColumns,
    searchQuery,
    payFilter,
    sourceFilter,
    statusFilter,
    totalItems,
    loading,
    options,
    authorTotalLocal,
    selectedRows,
    isFormActive,
    deleteDialog,
    confirmDelete,
    blankItem,
    form,
    page,
    pageCount,
    snackBarMessage,
    isSnackbarBottomVisible,
    avatarText,
    resetForm,
    openDialog,
    deleteItem,
    fetchItems,
    resolveAuthorRoleVariant,
    resolveAuthorRoleIcon,
    resolveAuthorStatusVariant,
    resolveAuthorStatusName,
    resolveAuthorTotalIcon,
  }
}
