import { ref, watch } from '@vue/composition-api'
import store from '@/store'

export default function controller() {
  const blankItem = {
    id: null,
    page_min: null,
    page_max: null,
    frais: null,
    subtraction: null,
    percentage: null,
  }
  const form = ref(null)
  const items = ref([])
  const item = ref({})
  const downloadObject = ref({ loading: false, url: '' })
  const filters = ref([
    {
      align: 'center',
      text: 'Dossier',
      value: 'publication_id',
      filter: { place_holder: 'Numero dossier...', type: 'text' },
    },
    {
      align: 'center',
      text: 'ISBN',
      value: 'isbn',
      filter: { place_holder: 'ISBN...', type: 'text' },
    },
    {
      text: 'Titre',
      value: 'manuscript.title',
      align: 'center',
      cellClass: 'red',
      filter: {
        place_holder: 'Titre...',
        type: 'text',
        column: 'manuscripts.title',
        join_table: 'manuscripts',
        join_column: 'manuscripts.id',
        local_column: 'isbn.manuscript_id',
      },
    },
    {
      align: 'center',
      text: 'Type',
      value: 'type',
      filter: {
        place_holder: 'type...',
        type: 'select',
        values: [
          /*{ id: 2, label: 'Broché' },*/
          { id: 1, label: 'Numerique' },
          { id: 'null', label: 'Inutilisé' },
        ],
        column: 'isbns.type',
        label: 'label',
        id: 'id',
        multiple: false,
      },
    },
    {
      text: 'Parution',
      value: 'parution',
      align: 'center',
      filter: {
        place_holder: 'Date - Date', type: 'date', range: true,
      },
    },
    /*{
      text: 'Actions',
      value: 'actions',
      align: 'center',
      sortable: false,
    },*/
  ])
  const isSnackbarBottomVisible = ref(false)
  const snackBarMessage = ref('')
  const isFormActive = ref(false)
  const isFormMultipleActive = ref(false)
  const deleteDialog = ref(false)
  const searchQuery = ref('')
  const totalItems = ref(0)
  const loading = ref(false)
  const options = ref({
    rowsPerPage: 50,
    itemsPerPage: 50,
    page: 1,
    sortDesc: [false],
  })
  const page = ref(1)
  const pageCount = ref(0)
  const selectedRows = ref([])
  const loadedManuscripts = ref([])
  const resetForm = () => {
    if (form.value) form.value.reset()
  }

  // new item
  const openDialog = val => {
    if (val) {
      item.value = val
    } else {
      item.value = JSON.parse(JSON.stringify(blankItem))
      resetForm()
    }
    isFormActive.value = !isFormActive.value
  }
  const openMultipleDialog = () => {
    isFormMultipleActive.value = !isFormMultipleActive.value
  }

  // fetch data
  const fetchItems = download => {
    isFormActive.value = false
    downloadObject.value = { loading: true, url: null }
    store
      .dispatch('isbn/fetchItems', {
        filters: filters.value.filter(ele => ele.filter != null && ele.filter.value !== '' && ele.filter.value != null && (ele.filter.value.constructor !== Array || ele.filter.value.length > 0)).map(ele => ({ ...ele.filter, key: ele.value, values: null })),
        term: searchQuery.value,
        download,
        orderBy: options.value.sortBy ? (options.value.sortBy[0] || 'publication_id') : 'publication_id',
        // eslint-disable-next-line no-nested-ternary
        direction: options.value.sortDesc ? (options.value.sortDesc[0] ? 'ASC' : 'DESC') : 'ASC',
        page: options.value.page,
        per_page: options.value.itemsPerPage,
      })
      .then(response => {
        if (download) {
          downloadObject.value = {
            loading: false,
            url: response.data || null,
          }
        } else {
          const { data, total } = response.data
          items.value = data
          totalItems.value = total
        }



        // remove loading state
        loading.value = false
      })
      .catch(error => {
        console.log(error)
      })
      .then(() => {
        loading.value = false
      })
  }
  const confirmDelete = val => {
    item.value = val
    deleteDialog.value = true
  }
  const deleteItem = () => {
    if (item.value) {
      loading.value = true
      store.dispatch('isbn/deleteItem', item.value.id)
        .then(response => {
          loading.value = false
          deleteDialog.value = false
          fetchItems()
          // eslint-disable-next-line no-unused-vars
        }).catch(error => {
        loading.value = false
      })
    }
  }

  // eslint-disable-next-line no-unused-vars
  let timeout = null
  watch([filters], () => {
    if (timeout) {
      clearTimeout(timeout)
    }
    timeout = setTimeout(() => {
      loading.value = true
      selectedRows.value = []
      fetchItems()
    }, 500)
  }, { deep: true })

  watch([searchQuery, options], () => {
    loading.value = true
    selectedRows.value = []
    fetchItems()
  }, { deep: true })

  return {
    item,
    items,
    filters,
    searchQuery,
    totalItems,
    loading,
    options,
    selectedRows,
    isFormActive,
    isFormMultipleActive,
    blankItem,
    form,
    page,
    pageCount,
    snackBarMessage,
    isSnackbarBottomVisible,
    deleteDialog,
    loadedManuscripts,
    downloadObject,
    resetForm,
    openDialog,
    openMultipleDialog,
    confirmDelete,
    deleteItem,
    fetchItems,
  }
}
