import {
  mdiAccountCheckOutline,
  mdiAccountOutline,
  mdiAccountPlusOutline,
  mdiAccountRemoveOutline,
  mdiCogOutline,
  mdiDatabaseOutline,
  mdiDnsOutline,
  mdiPencilOutline,
} from '@mdi/js'
import { ref, watch } from '@vue/composition-api'
import store from '@/store'

export default function controller() {
  const blankItem = {
    id: null,
    name: null,
    display_name: null,
    permissions: [],
  }
  const form = ref(null)
  const items = ref([])
  const item = ref({})
  const filters = ref([
    /*{ text: 'Code', value: 'name', filter: { type: 'text' } },*/
    { text: 'Name', value: 'display_name', translatable: true, filter: { type: 'text' } },
    { text: 'Shop role', value: 'shop', editable: false, },
    {
      text: 'Actions',
      value: 'actions',
      align: 'center',
      sortable: false,
    },
  ])
  const isSnackbarBottomVisible = ref(false)
  const snackBarMessage = ref('')
  const isFormActive = ref(false)
  const deleteDialog = ref(false)
  const searchQuery = ref('')
  const totalItems = ref(0)
  const loading = ref(false)
  const options = ref({
    rowsPerPage: 50,
    itemsPerPage: 50,
    page: 1,
    sortDesc: [true],
  })
  const page = ref(1)
  const pageCount = ref(0)
  const selectedRows = ref([])
  const resetForm = () => {
    if (form.value) form.value.reset()
  }

  // new item
  const openDialog = val => {
    if (val) {
      item.value = val
    } else {
      item.value = JSON.parse(JSON.stringify(blankItem))
      resetForm()
    }
    isFormActive.value = !isFormActive.value
  }

  // fetch data
  const fetchItems = () => {
    isFormActive.value = false
    store
      .dispatch('role/fetchItems', {
        filters: filters.value.filter(ele => ele.filter != null && ele.filter.value !== '' && ele.filter.value != null && (ele.filter.value.constructor !== Array || ele.filter.value.length > 0)).map(ele => ({ ...ele.filter, key: ele.value, values: null })),

        term: searchQuery.value,
        orderBy: options.value.sortBy ? options.value.sortBy[0] : 'id',
        // eslint-disable-next-line no-nested-ternary
        direction: options.value.sortDesc ? (options.value.sortDesc[0] ? 'DESC' : 'ASC') : 'DESC',
        page: options.value.page,
        per_page: options.value.itemsPerPage,
      })
      .then(response => {
        const { data, total } = response.data

        items.value = data.map(object => {
          object.permissions = object.permissions.map(perm => perm.name)

          return object
        })
        totalItems.value = total

        // remove loading state
        loading.value = false
      })
      .catch(error => {
        console.log(error)
      })
      .then(() => {
        loading.value = false
      })
  }
  const confirmDelete = val => {
    item.value = val
    deleteDialog.value = true
  }
  const deleteItem = () => {
    if (item.value && item.value.id) {
      store.dispatch('role/deleteItem', `/roles/${item.value.id}`)
        .then(response => {
          items.value.splice(items.value.findIndex((ele) => ele.id === item.value.id), 1)
          //fetchItems()
          // eslint-disable-next-line no-unused-vars
        }).catch(error => {

        })
    }
  }
  // eslint-disable-next-line no-unused-vars
  let timeout = null
  watch([filters], () => {
    if (timeout) {
      clearTimeout(timeout)
    }
    timeout = setTimeout(() => {
      loading.value = true
      selectedRows.value = []
      fetchItems()
    }, 500)
  }, { deep: true })

  watch([searchQuery, options], () => {
    loading.value = true
    selectedRows.value = []
    fetchItems()
  }, { deep: true })

  return {
    item,
    items,
    filters,
    searchQuery,
    totalItems,
    loading,
    options,
    selectedRows,
    isFormActive,
    blankItem,
    form,
    page,
    pageCount,
    snackBarMessage,
    isSnackbarBottomVisible,
    resetForm,
    deleteDialog,
    confirmDelete,
    openDialog,
    deleteItem,
    fetchItems,
  }
}
